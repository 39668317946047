import React, { FunctionComponent, forwardRef } from 'react'
import classNames from 'classnames'

import SectionWrapper from '_components/Common/SectionWrapper/SectionWrapper'
import Fade from '_components/Common/Fade/Fade'
import ScrollHint from '_components/Common/ScrollHint/ScrollHint'

import * as styles from './Section1.module.scss'

const Section1: FunctionComponent = function (_, ref) {
  return (
    <SectionWrapper
      ref={ref}
      className={classNames(
        styles.Section1,
        'd-flex flex-column align-items-center justify-content-center p-0',
      )}
    >
      <Fade>
        <header
          className={classNames(
            styles.Section1__header,
            'd-flex justify-content-center',
          )}
        >
          <h1 className="text-white text-center">
            anyone can develop
            <span className="text-blue-700">.</span>
          </h1>
        </header>
      </Fade>
      <Fade delay="0.6s">
        <article
          className={classNames(
            styles.Section1__article,
            'd-flex justify-content-center',
          )}
        >
          <h6 className="d-none d-md-block text-gray-500 text-center">
            구름은 ‘모두가 개발자가 된다’라는 비전으로 언제 어디서나 AI∙SW
            개발을 배우고,
            <br />
            원하는 결과물을 구현할 수 있도록 ‘개발자 성장 중심’의 생태계를
            만들어 나가고 있습니다.
          </h6>

          <h6 className="d-block d-md-none text-gray-500 text-center">
            구름은 ‘모두가 개발자가 된다’라는 비전으로
            <br />
            언제 어디서나 AI∙SW 개발을 배우고,
            <br />
            원하는 결과물을 구현할 수 있도록
            <br />
            ‘개발자 성장 중심’의 생태계를 만들어 나가고 있습니다.
          </h6>
        </article>
      </Fade>
      <ScrollHint />
    </SectionWrapper>
  )
}

export default forwardRef(Section1)
