import React, { FunctionComponent, useEffect, useState } from 'react'
import classNames from 'classnames'

import * as styles from './BackdropVideoPlayer.module.scss'

type BackdropVideoPlayerProps = {
  src: string
  poster?: string
  loop?: boolean
  muted?: boolean
  autoPlay?: boolean
  className?: string
}

const BackdropVideoPlayer: FunctionComponent<BackdropVideoPlayerProps> =
  function ({
    src,
    poster,
    loop = true,
    autoPlay = true,
    muted = true,
    className,
  }) {
    return (
      <div className={classNames(styles.BackdropVideoPlayer, className)}>
        {}
        <video
          className={styles.BackdropVideoPlayer__video}
          src={src}
          poster={poster}
          autoPlay={autoPlay}
          muted={muted}
          loop={loop}
          playsInline
          preload="none"
        />
        <div className={styles.BackdropVideoPlayer__dim} />
      </div>
    )
  }

export default BackdropVideoPlayer
