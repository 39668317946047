import React, { FunctionComponent, useEffect, useState, useRef } from 'react'
import classNames from 'classnames'
import { Button } from '@goorm-dev/components'
import BasicPagination from '@goorm-dev/components/build/BasicPagination'

import SectionHeader from '_components/Common/SectionHeader/SectionHeader'
import SectionWrapper from '_components/Common/SectionWrapper/SectionWrapper'
import NewsList from '_components/Main/NewsList/NewsList'

import { MainNewsItemType, MainNewsNodeType } from '_types/Main.types'

import * as styles from './Section7.module.scss'

const ITEM_LIMIT = 5

export type NewsItemType = {
  year: string
} & MainNewsItemType

type Section7Props = {
  list: MainNewsNodeType[]
}

const Section7: FunctionComponent<Section7Props> = function ({ list }) {
  //** aside 관련
  // NOTE: GraphQL에서 date순으로 sort되어서 내려옴 -> 가장 최근 년도(배열 첫 번째)를 activeYear로 설정
  const [activeYear, setActiveYear] = useState<string>(
    !!list[0]?.node.date
      ? String(new Date(list[0].node?.date).getFullYear())
      : '',
  )

  //** article 관련
  const [dateList, setDateList] = useState<string[]>([
    String(new Date().getFullYear()),
  ])
  const [newsList, setNewsList] = useState<NewsItemType[]>([
    {
      id: '',
      title: '',
      date: '',
      link: '',
      year: '',
    },
  ])
  const filterList = newsList.filter(item => item.year === activeYear)

  //**  footer(pagination) 관련
  const total = filterList.length
  const headerRef = useRef<HTMLDivElement>(null)
  const [page, setPage] = useState<number>(1)
  const [start, setStart] = useState<number>(0)
  const [end, setEnd] = useState<number>(ITEM_LIMIT)

  const handleScrollMove = () => {
    headerRef.current?.scrollIntoView({ behavior: 'smooth' })
  }
  const handleButton = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const { id } = e.currentTarget

    setActiveYear(id)
    setPage(1)
    setStart(0)
    setEnd(ITEM_LIMIT)
  }
  const handlePagination = (num: string) => {
    const page = Number(num)

    setPage(page)
    setStart((page - 1) * ITEM_LIMIT)
    setEnd((page - 1) * ITEM_LIMIT + ITEM_LIMIT)
    handleScrollMove()
  }

  useEffect(() => {
    const yearSet = new Set<string>()
    const newsSet = new Set<NewsItemType>()

    list.forEach(({ node: { id, title, link, date } }) => {
      const year = String(new Date(date).getFullYear())

      yearSet.add(year)
      newsSet.add({
        id,
        title,
        link,
        date,
        year,
      })
    })

    setDateList([...yearSet])
    setNewsList([...newsSet])
  }, [])

  return (
    <SectionWrapper className={classNames(styles.Section7, 'bg-white')}>
      <SectionHeader className={styles.Section7__header}>
        <h1 ref={headerRef}>구름 뉴스</h1>
      </SectionHeader>
      <aside className={styles.Section7__aside}>
        <div className={classNames(styles.Section7__asideWrapper, 'd-flex')}>
          {dateList.map(item => (
            <Button
              key={item}
              id={item}
              color="link"
              active={activeYear === item}
              onClick={handleButton}
              className={styles.Section7__asideBtn}
            >
              {item}
            </Button>
          ))}
        </div>
      </aside>
      <article>
        <NewsList start={start} end={end} list={filterList} />
      </article>
      <footer
        className={classNames('justify-content-center mt-5', {
          'd-none': Math.ceil(total / ITEM_LIMIT) === 1,
          'd-flex': Math.ceil(total / ITEM_LIMIT) !== 1,
        })}
      >
        <BasicPagination
          size="md"
          page={page}
          pageCount={Math.ceil(total / ITEM_LIMIT)}
          limitCount={ITEM_LIMIT}
          onPageChangeHandler={handlePagination}
          scrollMove={false}
          className={styles.Section7__pagination}
        />
      </footer>
    </SectionWrapper>
  )
}

export default React.memo(Section7)
