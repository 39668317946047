import React, { FunctionComponent, useState, useEffect, useRef } from 'react'
import { graphql } from 'gatsby'

import Header from '_components/Common/Header/Header'
import PageWrapper from '_components/Common/PageWrapper/PageWrapper'
import RecruitBannerSection from '_containers/Common/RecruitBannerSection/RecruitBannerSection'
import BackdropVideoPlayer from '_components/Common/BackdropVideoPlayer/BackdropVideoPlayer'
import Section1 from '_containers/Main/Section1/Section1'
import Section2 from '_containers/Main/Section2/Section2'
import Section3 from '_containers/Main/Section3/Section3'
import Section4 from '_containers/Main/Section4/Section4'
import Section5 from '_containers/Main/Section5/Section5'
import Section6 from '_containers/Main/Section6/Section6'
import Section7 from '_containers/Main/Section7/Section7'
// NOTE: 디자인팀 요청으로 잠시 가리기
// import Section8 from '_containers/Main/Section8/Section8'

import useGridBreakpoint from '_hooks/useGridBreakpoint'

import MOBILE_IMAGE_SRC from '_images/Main/main-section1-mobile-image.png'
import VIDEO_SRC from '_images/Main/main-section1-video.mp4'

import { MainPageType } from '_types/Main.types'

type MainPageProps = MainPageType

const IndexPage: FunctionComponent<MainPageProps> = function ({
  data: {
    mainNewsData: { edges: mainNewsList },
    mainPartnersData: { edges: mainPartnerList },
  },
}) {
  const sectionsRef = useRef<HTMLDivElement[]>(Array.from({ length: 5 }))
  const [headerColor, setHeaderColor] = useState<string>('dark')
  const currentBreakpoint: string = useGridBreakpoint([
    'xs',
    'sm',
    'md',
    'lg',
    'xl',
  ])

  useEffect(() => {
    let animationThrottling = false

    const onScroll = () => {
      if (animationThrottling) return
      animationThrottling = true
      const scrollY = window.pageYOffset
      const section1Height = sectionsRef.current[0]?.clientHeight
      const section2Height = sectionsRef.current[1]?.clientHeight
      const section3Height = sectionsRef.current[2]?.clientHeight
      const section4Height = sectionsRef.current[3]?.clientHeight
      const section5Height = sectionsRef.current[4]?.clientHeight

      requestAnimationFrame(() => {
        animationThrottling = false
      })

      // Section1-2 에 있음
      let sum = section1Height + section2Height
      if (scrollY < sum) {
        setHeaderColor('dark')
        return
      }

      // Section 3-4 에 있음
      sum += section3Height + section4Height
      if (scrollY < sum) {
        setHeaderColor('light')
        return
      }

      // Section5에 있음
      sum += section5Height
      if (scrollY < sum) {
        setHeaderColor('dark')
        return
      }

      // Section6 ~ Footer에 있음
      setHeaderColor('light')
    }

    document.addEventListener('scroll', onScroll, {
      passive: true,
    })
  }, [])

  return (
    <PageWrapper Header={<Header color={headerColor} />}>
      <BackdropVideoPlayer
        poster={MOBILE_IMAGE_SRC}
        src={currentBreakpoint !== 'xs' ? VIDEO_SRC : ''}
        className="bg-black"
      />
      <Section1
        ref={ref => {
          sectionsRef.current[0] = ref
        }}
      />
      <Section2
        ref={ref => {
          sectionsRef.current[1] = ref
        }}
      />
      <Section3
        ref={ref => {
          sectionsRef.current[2] = ref
        }}
        currentBreakpoint={currentBreakpoint}
      />
      <Section4
        ref={ref => {
          sectionsRef.current[3] = ref
        }}
      />
      <Section5
        ref={ref => {
          sectionsRef.current[4] = ref
        }}
        currentBreakpoint={currentBreakpoint}
      />
      <Section6 list={mainPartnerList[0]} />
      <Section7 list={mainNewsList} />
      {
        // NOTE: 디자인팀 요청으로 잠시 가리기
        //<Section8 />
      }
      <RecruitBannerSection />
    </PageWrapper>
  )
}

export default IndexPage

export const getMainPageMarkdownData = graphql`
  query getMainPageMarkdownData {
    mainNewsData: allStrapiMainNewsList(sort: { order: DESC, fields: [date] }) {
      edges {
        node {
          title
          date
          link
          id
        }
      }
    }
    mainPartnersData: allStrapiMainPartnersList {
      edges {
        node {
          src {
            id
            name
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`
