import React, { useRef, useEffect, useState } from 'react'
import * as lottie from 'lottie-web'
import PropTypes from 'prop-types'

const useLottie = (animationWrapperRef, options) => {
  const [animation, setAnimation] = useState(null)
  const {
    loop,
    speed,
    animationData,
    preserveAspectRatio,
    viewBoxSize,
    stopped,
    paused,
    reverse,
    onComplete,
    onLoopComplete,
  } = options

  const loadAnimation = () => {
    const loadedAnimation = lottie.loadAnimation({
      container: animationWrapperRef.current,
      renderer: 'svg',
      loop,
      autoplay: true,
      animationData: animationData.default
        ? animationData.default
        : animationData,
      rendererSettings: {
        preserveAspectRatio,
        progressiveLoad: true, // only svg renderer
        ...(viewBoxSize && { viewBoxSize }),
      },
    })
    loadedAnimation.setDirection(reverse ? -1 : 1)
    loadedAnimation.setSpeed(speed)
    loadedAnimation.onComplete = event => {
      onComplete(event)
    }
    loadedAnimation.onLoopComplete = event => {
      onLoopComplete(event)
    }
    if (stopped) {
      loadedAnimation.stop()
    }
    if (paused) {
      loadedAnimation.pause()
    }
    if (!stopped && !paused) {
      loadedAnimation.play()
    }

    setAnimation(loadedAnimation)
  }

  useEffect(() => {
    if (animation) {
      animation.destroy()
    }
    loadAnimation()
    return () => {
      animation && animation.destroy()
    }
  }, [loop, preserveAspectRatio])

  useEffect(() => {
    animation && speed > 0 && animation.setSpeed(speed)
  }, [speed])

  useEffect(() => {
    if (animation) {
      if (stopped) {
        animation.stop()
      } else if (!paused) {
        animation.play()
      }
    }
  }, [stopped])

  useEffect(() => {
    if (animation) {
      if (paused) {
        animation.pause()
      } else if (!stopped) {
        animation.play()
      }
    }
  }, [paused])

  useEffect(() => {
    animation && animation.setDirection(reverse ? -1 : 1)
  }, [reverse])
}

const LottiePlayer = props => {
  const animationWrapperRef = useRef()
  const {
    loop,
    speed,
    animationData,
    preserveAspectRatio,
    viewBoxSize,
    stopped,
    paused,
    reverse,
    onComplete,
    onLoopComplete,
  } = props

  useLottie(animationWrapperRef, {
    loop,
    speed,
    animationData,
    preserveAspectRatio,
    viewBoxSize,
    stopped,
    paused,
    reverse,
    onComplete,
    onLoopComplete,
  })
  return (
    <div
      ref={animationWrapperRef}
      className={props.className}
      style={props.style}
    />
  )
}

LottiePlayer.defaultProps = {
  loop: true,
  stopped: false,
  paused: false,
  speed: 1,
  reverse: false,
  preserveAspectRatio: 'xMidYMid meet', // 꽉 채우려면 xMidYMid slice(대신 잘릴 수가 있다)
  viewBoxSize: null,
  onComplete: event => event,
  onLoopComplete: event => event,
  className: '',
  style: {},
}

LottiePlayer.propTypes = {
  loop: PropTypes.bool, // 반복
  stopped: PropTypes.bool, // 정지
  paused: PropTypes.bool, // 일시 정지
  speed: PropTypes.number, // 속도 ( > 0), 소수점 가능
  reverse: PropTypes.bool, // 거꾸로 재생
  preserveAspectRatio: PropTypes.string, // 영상 비율 정의 https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/preserveAspectRatio
  viewBoxSize: PropTypes.string, // viewBox 지정 https://developer.mozilla.org/ko/docs/Web/SVG/Attribute/viewBox
  animationData: PropTypes.objectOf(PropTypes.any).isRequired, // 모션 json
  onComplete: PropTypes.func, // loop===false일 때 애니메이션 끝나면 호출됨
  onLoopComplete: PropTypes.func, // loop===true일 때 루프 끝나면 호출됨
  className: PropTypes.string, // animation wrapper 가로 세로 길이 등, 스타일은 css로 지정
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]), // style
}
export default LottiePlayer
