import React, { useRef, ReactNode } from 'react'
import classNames from 'classnames'

import useIntersectionObserver from '_hooks/useIntersectionObserver'
import { IntersectionObserverType } from '_types/IntersectionObserver.types'

import * as styles from './Fade.module.scss'

type FadeProps = {
  duration: string
  durationFunc: string
  delay: string
  distance: string
  direction: string
  className: string
  children: ReactNode
} & IntersectionObserverType

const Fade = ({
  threshold,
  rootMargin,
  freezeOnceVisible,

  duration,
  durationFunc,
  delay,
  distance,
  direction,

  className,
  children,
}: FadeProps) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const entry = useIntersectionObserver(ref, {
    threshold,
    rootMargin,
    freezeOnceVisible,
  })
  const isIntersecting = !!entry?.isIntersecting

  const fadeStyle = {
    top: {
      transition: `transform ${duration} ${durationFunc} ${delay}, opacity ${duration} ${durationFunc} ${delay}`,
      transform: `translate3d(0px, -${isIntersecting ? 0 : distance}, 0)`,
    },
    right: {
      transition: `transform ${duration} ${durationFunc} ${delay}, opacity ${duration} ${durationFunc} ${delay}`,
      transform: `translate3d(${isIntersecting ? 0 : distance},0px, 0)`,
    },
    bottom: {
      transition: `transform ${duration} ${durationFunc} ${delay}, opacity ${duration} ${durationFunc} ${delay}`,
      transform: `translate3d(0px, ${isIntersecting ? 0 : distance}, 0)`,
    },
    left: {
      transition: `transform ${duration} ${durationFunc} ${delay}, opacity ${duration} ${durationFunc} ${delay}`,
      transform: `translate3d(-${isIntersecting ? 0 : distance},0px, 0)`,
    },
  }

  return (
    <div
      ref={ref}
      style={fadeStyle[direction]}
      className={classNames(
        styles.Fade,
        isIntersecting && styles.Fade__active,
        className,
      )}
    >
      {children}
    </div>
  )
}

Fade.defaultProps = {
  threshold: 0.7, // 최소:0, 최대:1, ex. 박스의 0.7이 보일때 fade
  rootMargin: '0%',
  freezeOnceVisible: true, // Fade 한 번만할건지

  duration: '0.7s', // transition-duration
  durationFunc: 'ease-in-out', // transition-timing-function
  delay: '0s', // transition-delay
  distance: '3.125rem', // fade가 몇 rem 아래 | 위 | 왼쪽 | 오른쪽에서 될지
  direction: 'bottom', // fade가 일어나는 방향

  className: '',
}

export default Fade
