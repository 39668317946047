import React, { useEffect, useRef } from 'react'
import cx from 'classnames'

import * as styles from './AnimatedCount.module.scss'

const setAnimation = stat => {
  const patt = /(\D+)?(\d+)(\D+)?(\d+)?(\D+)?/
  let result = [...patt.exec(stat.textContent)]

  result.shift()
  result = result.filter(res => res != null)

  while (stat.firstChild) {
    stat.removeChild(stat.firstChild)
  }

  result.forEach(res => {
    if (Number.isNaN(Number(res))) {
      stat.insertAdjacentHTML('beforeend', `<span>${res}</span>`)
    } else {
      for (let i = 0; i < res.length; i += 1) {
        const spanList = [...Array(Number.parseInt(res[i], 10) + 1).keys()].map(
          n => `<span>${n}</span>`,
        )
        stat.insertAdjacentHTML(
          'beforeend',
          `<span data-value="${res[i]}">
						<span>&ndash;</span>
						${spanList.join('')}
					</span>`,
        )
      }
    }
  })
  const ticks = [...stat.querySelectorAll('span[data-value]')]

  const activate = () => {
    /* eslint-disable no-param-reassign */
    ticks.forEach(tick => {
      const dist = Number.parseInt(tick.getAttribute('data-value'), 10) + 1
      tick.style.transform = `translateY(-${dist * 100}%)`
    })
    /* eslint-disable no-param-reassign */
  }

  return { activate }
}

const useCountAnimation = () => {
  const ref = useRef(null)

  useEffect(() => {
    const { activate } = setAnimation(ref.current)
    if (window.IntersectionObserver) {
      const io = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              activate()
            }
          })
        },
        { threshold: 1 },
      )
      io.observe(ref.current)
    }
  }, [])
  return {
    ref,
  }
}

function AnimatedCount({ number, className, countClassName }) {
  const { ref } = useCountAnimation()

  return (
    <div className={cx(styles.AnimatedCount, className)}>
      <h3 className={cx(styles.AnimatedCount__count, countClassName)}>
        <div ref={ref} aria-label={number}>
          {number}
        </div>
      </h3>
    </div>
  )
}

AnimatedCount.defaultProps = {
  number: '1,234',
  className: '',
  countClassName: '',
}

export default AnimatedCount
