import React, { FunctionComponent, forwardRef } from 'react'
import classNames from 'classnames'

import * as styles from './Section2.module.scss'

import VIDEO_SRC from '_images/Main/main-section2-video.png'
import VIDEO_SRC_MOBILE from '_images/Main/main-section2-mobile-video.png'

const Section2: FunctionComponent = function (_, ref) {
  return (
    <section
      ref={ref}
      className={classNames(
        styles.Section2,
        'd-flex flex-column align-items-center justify-content-center',
      )}
    >
      <img
        src={VIDEO_SRC}
        className={classNames(styles.Section2__video, 'd-sm-block d-none')}
      />
      <img
        src={VIDEO_SRC_MOBILE}
        className={classNames(styles.Section2__video, 'd-sm-none d-block')}
      />
    </section>
  )
}

export default forwardRef(Section2)
