import React, { FunctionComponent } from 'react'
import classNames from 'classnames'

import * as styles from './AutoCaruosel.module.scss'

type CarouselItemType = {
  id: string | number
  url?: string
  name?: string
}
type AutoCaruoselProps = {
  direction?: string
  className?: string
  list: CarouselItemType[]
}

const AutoCaruosel: FunctionComponent<AutoCaruoselProps> = function ({
  direction = 'left',
  list = [],
  className = '',
}) {
  return (
    <div className={classNames(styles.AutoCaruosel, className)}>
      <div
        className={classNames(styles.AutoCaruosel__line, {
          [styles.AutoCaruosel__lineLeft]: direction === 'left',
          [styles.AutoCaruosel__lineRight]: direction === 'right',
        })}
      >
        {list.map(item => (
          <div
            key={item.id}
            className={classNames(styles.AutoCaruosel__item, 'bg-white')}
          >
            <img src={item?.localFile?.publicURL} alt={item.name} />
          </div>
        ))}
        {list.map(item => (
          <div
            key={item.id}
            className={classNames(
              styles.AutoCaruosel__item,
              'd-flex align-items-center bg-white',
            )}
          >
            <img src={item?.localFile?.publicURL} alt={item.name} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default AutoCaruosel
