import React, { FunctionComponent, forwardRef } from 'react'
import classNames from 'classnames'
import { Container, Row, Col } from '@goorm-dev/components'

import SectionWrapper from '_components/Common/SectionWrapper/SectionWrapper'
import SectionHeader from '_components/Common/SectionHeader/SectionHeader'
import Fade from '_components/Common/Fade/Fade'

import * as styles from './Section3.module.scss'

import STATIC_IMG_URL from '_images/Main/main-section3-1.png'
import STATIC_VIDEO_URL from '_images/Main/main-section3-1.mp4'

const ITEMS = [
  {
    id: 1,
    header: '누구나 공정하게',
    description: '소프트웨어 역량을 기를 수 있도록',
    fadeDelay: '0.6s',
  },
  {
    id: 2,
    header: '누구나 정확하게',
    description: '소프트웨어 역량을 평가받을 수 있도록',
    fadeDelay: '0.8s',
  },
  {
    id: 3,
    header: '누구나 자유롭게',
    description: '소프트웨어 역량을 펼칠 수 있도록',
    fadeDelay: '1s',
  },
]

type ContentBoxProps = {
  header: string
  description: string
  fadeDelay: string
}
type Section3Props = {
  currentBreakpoint: string
}

const ContentBox: FunctionComponent<ContentBoxProps> = function ({
  header,
  description,
  fadeDelay,
}) {
  return (
    <Fade
      freezeOnceVisible={true}
      direction="left"
      distance="1.25rem"
      delay={fadeDelay}
      className="w-100 h-100"
    >
      <div
        className={classNames(
          styles.ContentBox,
          'h-100 d-flex flex-xl-row align-items-xl-center justify-content-xl-start flex-column justify-content-center',
        )}
      >
        <h3 className="text-blue-500 mr-2">{header}</h3>
        <h3 className={classNames('text-white', styles.ContentBox__desc)}>
          {description}
        </h3>
      </div>
    </Fade>
  )
}
const Section3: FunctionComponent<Section3Props> = function (
  { currentBreakpoint },
  ref,
) {
  return (
    <SectionWrapper
      ref={ref}
      className={classNames(
        styles.Section3,
        'd-flex align-items-center bg-gray-50',
      )}
    >
      <SectionHeader
        className={classNames(styles.Section3__header, 'd-flex flex-column')}
      >
        <h6 className="text-gray-600">구름이 존재하는 이유</h6>
        <h2 className="text-center">
          모두가 자신의 한계를 뛰어넘어
          <br />
          슈퍼 휴먼이 되는 세상을 만드는 것
        </h2>
      </SectionHeader>

      <article>
        <Container fluid="lg" className="px-0">
          <Row className="d-flex justify-content-center">
            <Col xl="5" lg="6" md="8" xs="12">
              <Fade
                freezeOnceVisible={true}
                direction="left"
                distance="1.25rem"
                className="d-flex w-100"
              >
                <video
                  playsInline
                  autoPlay
                  muted
                  loop
                  src={currentBreakpoint !== 'xs' ? STATIC_VIDEO_URL : ''}
                  poster={STATIC_IMG_URL}
                  className={styles.Section3__video}
                />
              </Fade>
            </Col>
            <Col xl="7" lg="6" md="8" xs="12">
              <div className={styles.Section3__content}>
                {ITEMS.map(item => (
                  <ContentBox
                    key={item.id}
                    header={item.header}
                    description={item.description}
                    fadeDelay={item.fadeDelay}
                  />
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </article>
    </SectionWrapper>
  )
}

export default forwardRef(Section3)
