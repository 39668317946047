import React, { FunctionComponent } from 'react'
import classNames from 'classnames'

import SectionHeader from '_components/Common/SectionHeader/SectionHeader'
import SectionWrapper from '_components/Common/SectionWrapper/SectionWrapper'
import AnimatedCount from '_components/Common/AnimatedCount/AnimatedCount'
import AutoCaruosel from '_components/Common/AutoCaruosel/AutoCaruosel'
import Fade from '_components/Common/Fade/Fade'

import { MainPartnersItemType } from '_types/Main.types'

import * as styles from './Section6.module.scss'

type Section6Props = {
  list: {
    node: {
      data: {
        [key: string]: MainPartnersItemType[]
      }
    }
  }
}

const Section6: FunctionComponent<Section6Props> = function ({
  list: {
    node: { src },
  },
}) {
  const partners = src.sort((a, b) => {
    const prevOrder = a.name.split('_')[0] * 1
    const nextOrder = b.name.split('_')[0] * 1

    if (prevOrder < nextOrder) return -1
    if (prevOrder > nextOrder) return 1
    return 0
  })

  //const partners: MainPartnersItemType[] = PARTNER_LIST

  return (
    <SectionWrapper className={classNames(styles.Section6, 'bg-gray-50')}>
      <SectionHeader className="flex-column text-center">
        <h1 className="d-sm-flex d-none mb-3">
          함께 성장중인
          <AnimatedCount number={'1,500'} className="ml-2" />+ 개 파트너사
        </h1>
        <h1 className="d-sm-none d-block mb-3">
          함께 성장중인
          <span className="d-flex">
            <AnimatedCount number={'1,500'} /> + 개 파트너사
          </span>
        </h1>
        <h6 className="text-gray-600">
          AI∙SW 분야를 선도하고 있는 <br />
          다양한 기업과 기관에서 구름과 함께하고 있습니다.
        </h6>
      </SectionHeader>

      <article className="d-xxl-block d-none">
        <div className={styles.Section6__partnerWrapper}>
          {partners.map(item => (
            <Fade
              key={item.id}
              threshold={1}
              freezeOnceVisible={true}
              duration={'0.7s'}
            >
              <div className={classNames(styles.Section6__partner, 'bg-white')}>
                <img src={item?.localFile?.publicURL} alt={item.name} />
              </div>
            </Fade>
          ))}
        </div>
      </article>
      <article className="d-xxl-none d-block">
        <div className={styles.Section6__mobilePartnerWrapper}>
          <div className={styles.Section6__carouselWrapper}>
            <AutoCaruosel
              list={partners.filter((_, idx) => idx < 12)}
              className="mb-4"
            />
            <AutoCaruosel
              list={partners.filter((_, idx) => idx >= 12 && idx < 24)}
              direction="right"
              className="mb-4"
            />
            <AutoCaruosel
              list={partners.filter((_, idx) => idx >= 24 && idx < 36)}
              className="mb-4"
            />
            <AutoCaruosel
              list={partners.filter((_, idx) => idx >= 36 && idx < 48)}
              direction="right"
            />
          </div>
        </div>
      </article>
    </SectionWrapper>
  )
}

export default Section6
