import React, { FunctionComponent, forwardRef } from 'react'
import { Link as GBLink } from 'gatsby'
import { Container, Row, Col, Button, Badge } from '@goorm-dev/components'
import classNames from 'classnames'

import SectionWrapper from '_components/Common/SectionWrapper/SectionWrapper'

import * as styles from './Section5.module.scss'

import STATIC_VIDEO_URL from '_images/Common/common-ecosystem.png'
import STATIC_IMG_URL from '_images/Common/common-ecosystem-static.png'

const ITEMS = [
  {
    id: 1,
    badge: '구름EDU',
    text: '에서 AI∙SW 지식을 배우고',
  },
  {
    id: 2,
    badge: '구름DEVTH',
    text: '로 실력을 평가받고',
  },
  {
    id: 3,
    badge: '구름IDE',
    text: '로 아이디어를 펼치세요.',
  },
  {
    id: 4,
    text: '모두가 개발자가 되는 멋진 세상을 만듭니다. ',
  },
]

type Section5Props = {
  currentBreakpoint: string
}

const Section5: FunctionComponent<Section5Props> = function (
  { currentBreakpoint },
  ref,
) {
  return (
    <SectionWrapper
      ref={ref}
      className={classNames(
        'd-flex flex-column justify-content-center',
        styles.Section5,
      )}
    >
      <Container fluid="lg" className="px-0">
        <Row className="d-flex justify-content-center">
          <Col
            lg={{
              size: 7,
              order: 1,
            }}
            md="8"
            xs={{
              size: 12,
              order: 2,
            }}
          >
            <img
              src={
                currentBreakpoint === 'xs' ? STATIC_IMG_URL : STATIC_VIDEO_URL
              }
              alt="main-section3-1"
              className={styles.Section5__img}
            />
          </Col>
          <Col
            xl={{
              size: 4,
              offset: 1,
            }}
            lg={{
              size: 5,
              order: 2,
            }}
            md="8"
            xs={{
              size: 12,
              order: 1,
            }}
            className="d-flex flex-column align-items-lg-start align-items-center justify-content-center"
          >
            <h2 className="text-white mb-4 text-center">
              구름이 만들어가는 생태계
            </h2>

            <ul
              className={classNames(
                styles.Section5__list,
                'd-flex flex-column align-items-lg-start align-items-center',
              )}
            >
              {ITEMS.map(item => (
                <li key={item.id} className={styles.Section5__item}>
                  {!!item.badge && (
                    <Badge color="primary" className="mr-1">
                      {item.badge}
                    </Badge>
                  )}
                  <span
                    className={classNames(
                      styles.Section5__itemText,
                      'text-gray-500',
                    )}
                  >
                    {item.text}
                  </span>
                </li>
              ))}
            </ul>
            <GBLink to="/platform">
              <Button size="xl" color="light" className="d-none d-sm-block">
                구름 플랫폼 바로가기
              </Button>
            </GBLink>
            <GBLink to="/platform">
              <Button size="lg" color="light" className="d-block d-sm-none">
                구름 플랫폼 바로가기
              </Button>
            </GBLink>
          </Col>
        </Row>
      </Container>
    </SectionWrapper>
  )
}

export default forwardRef(Section5)
