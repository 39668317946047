import React, { FunctionComponent, forwardRef } from 'react'
import classNames from 'classnames'
import { Badge } from '@goorm-dev/components'

import SectionHeader from '_components/Common/SectionHeader/SectionHeader'
import SectionWrapper from '_components/Common/SectionWrapper/SectionWrapper'
import Fade from '_components/Common/Fade/Fade'
import LottiePlayer from '_components/Common/LottiePlayer/LottiePlayer'

import * as styles from './Section4.module.scss'

import Circle from '_images/Main/assets/main-section4-circle.svg'
import ASSET_BLINK from '_images/Main/main-section4-blink.json'

const ITEMS = [
  {
    id: '2021-history',
    year: '2021',
    title: '구름 서비스 가입자수 70만명 돌파',
    desc: [
      `혁신기업 국가대표 1000 선정`,
      `구름IDE ‘팀플러스’ 출시`,
      `카카오프렌즈와 함께하는 코딩운동회 콘텐츠 운영`,
      `실시간 화상 감독 서비스 ‘옵저뷰’ 출시`,
      `카카오엔터프라이즈-한국과학창의재단 업무협약 체결`,
      `SaaS 및 교육 분야 협업을 위한 카카오엔터프라이즈 업무협약 체결`,
      `NIA ‘공공 클라우드 플래그십 프로젝트’ 선정`,
      `‘정보문화의 달 유공’ 과학기술정보통신부 장관 표창`,
      `고용노동부 ‘K-디지털 트레이닝’ 교육 기관 선정`,
      `고용노동부 ‘K-디지털 크레딧’ 교육 기관  선정`,
    ],
  },
  {
    id: '2020-history',
    year: '2020',
    title: '구름 서비스 가입자수 30만명 돌파',
    desc: [
      '구름IDE 엔터프라이즈 출시',
      'SW고성장클럽200 사업 선정',
      '글로벌SW전문기업 육성 사업 선정',
    ],
  },
  {
    id: '2013-2019-history',
    year: '2013 - 2019',
    title: '구름 서비스 가입자수 20만명 돌파',
    desc: [
      '구름IDE 해외 리전 오픈 (Oregon, Frankfurt, Mumbai)',
      '한국코드페어, 한국정보올림피아드 운영',
      '온라인 코딩 파티 운영사로 선정',
      'NIA ‘교육분야 IoT 융합서비스 실증사업’ 우수 과제',
      'NHN 10억원 투자 유치',
      '초중고 SW교육 클라우드 시범 지원사업 참여',
    ],
  },
]

type HistoryProps = {
  id: string
  year: string
  title: string
  desc: string[]
}

const History: FunctionComponent<HistoryProps> = function ({
  year,
  title,
  desc,
}) {
  return (
    <div className={classNames(styles.History, 'd-table')}>
      <Circle className={styles.Section4__circle} />
      <Fade
        threshold={1}
        delay="0.2s"
        direction="left"
        distance="1.25rem"
        freezeOnceVisible={true}
        className="justify-content-start"
      >
        <div className="d-sm-block d-none mb-3">
          <Badge color="primary" size="lg">
            {year}
          </Badge>
        </div>
        <div className="d-sm-none d-block mb-3">
          <Badge color="primary" size="md">
            {year}
          </Badge>
        </div>

        <h3 className="text-blue-500 mb-2">{title}</h3>

        <ul>
          {desc.map(li => (
            <li key={li} className="text-gray-800">
              {li}
            </li>
          ))}
        </ul>
      </Fade>
    </div>
  )
}

const Section4: FunctionComponent = function (_, ref) {
  return (
    <SectionWrapper
      ref={ref}
      className={classNames(styles.Section4, 'bg-white')}
    >
      <SectionHeader className="flex-column text-center">
        <h1 className="mb-3">구름은 매년 성장 중</h1>
        <h6 className="text-gray-600 d-sm-block d-none">
          플랫폼을 개발하고 운영하면서 쌓은 노하우를 바탕으로
          <br />
          SW 교육 비즈니스를 확장하고 있습니다.
        </h6>
        <h6 className="text-gray-600 d-sm-none d-block">
          플랫폼을 개발하고 운영하면서
          <br /> 쌓은 노하우를 바탕으로
          <br />
          SW 교육 비즈니스를 확장하고 있습니다.
        </h6>
      </SectionHeader>
      <article className="d-flex justify-content-center">
        <div className={styles.Section4__historyBarWrapper}>
          <LottiePlayer
            loop
            animationData={ASSET_BLINK}
            className={styles.Section4__blink}
          />
          <span className={styles.Section4__historyBar} />
        </div>

        <div className="d-flex flex-column flex-start">
          {ITEMS.map(item => (
            <History
              key={item.id}
              year={item.year}
              title={item.title}
              desc={item.desc}
            />
          ))}
        </div>
      </article>
    </SectionWrapper>
  )
}

export default forwardRef(Section4)
