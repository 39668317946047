import React, { FunctionComponent } from 'react'
import classNames from 'classnames'
import ListGroup from '_components/Common/ListGroup/ListGroup'
import ListGroupItem from '_components/Common/ListGroupItem/ListGroupItem'

import { NewsItemType } from '_containers/Main/Section7/Section7'

import { formattedDate } from '_utils/utils'

import * as styles from './NewsList.module.scss'

type NewsListProps = {
  list: NewsItemType[]
  start: number
  end: number
}

const NewsList: FunctionComponent<NewsListProps> = function ({
  list,
  start,
  end,
}) {
  return (
    <ListGroup>
      {list.slice(start, end).map(item => (
        <ListGroupItem key={item.id} className={styles.NewsList__item}>
          <a href={item.link} target="_blank">
            <h5 className={classNames(styles.NewsList__itemHeader, 'mb-3')}>
              {item.title}
            </h5>
          </a>
          <p className="paragraph-sm text-gray-600">
            {formattedDate(item.date)}
          </p>
        </ListGroupItem>
      ))}
    </ListGroup>
  )
}

export default NewsList
