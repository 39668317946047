import React from 'react'
import cx from 'classnames'
import { Link } from 'gatsby'
import { Button, Container } from '@goorm-dev/components'
import * as styles from './RecruitBannerSection.module.scss'
import Fade from '_components/Common/Fade/Fade'
import Responsive from '_components/Common/Responsive/Responsive'

const RecruitBannerSection: React.FC = function () {
  const headerClasses = cx([
    styles.HeaderWithContents__textHead,
    'text-center',
    'text-white',
    'mb-2',
  ])
  const contentClasses = cx([styles.HeaderWithContents__textSub, 'text-center'])

  return (
    <section className={cx([styles.RecruitBannerSection, 'text-center'])}>
      <Container fluid="lg">
        <Fade
          distance="50px"
          durationFunc="ease-out"
          duration="0.6s"
          threshold={0.4}
          freezeOnceVisible
        >
          <div className={styles.HeaderWithContents}>
            <h1 className={headerClasses}>
              구름과 함께할 <span className="text-blue-500">뉴 - 구르미</span>를
              찾습니다
            </h1>
            <p className={contentClasses}>
              무한한 성장 가능성을 가진 팀을 찾고 있다면{' '}
              <br className="d-sm-none d-block" />
              지금 지원하세요!
            </p>
          </div>
        </Fade>
        <Fade
          distance="50px"
          durationFunc="ease-out"
          duration="0.5s"
          threshold={0.4}
          freezeOnceVisible
        >
          <Responsive
            def={
              <Link to="/recruit">
                <Button size="xl" color="light">
                  채용 바로가기
                </Button>
              </Link>
            }
            md={
              <Link to="/recruit">
                <Button size="lg" color="light">
                  채용 바로가기
                </Button>
              </Link>
            }
          />
        </Fade>
      </Container>
    </section>
  )
}

export default RecruitBannerSection
